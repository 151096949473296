import styled from "styled-components";

import { Input as InputCustom, Button as ButtonCustom } from 'antd';

export const Container = styled.div`
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */

  background-color: #f4f4f4;
  background-image: url('https://uploads-ssl.webflow.com/63a2d077108467714aa70e7f/63a2d140bbec1b69f01d96e5_Fra32me%201%20(1).png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  /* backdrop-filter: blur(20px); */
`;

export const Content = styled.div`
  padding: 24px 48px;
  margin: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  backdrop-filter: blur(15px);
  background-color: rgba(255,255,255, 0.5);

  border-radius: 20px;

  border: 1px solid #fff;
 
  @media (max-width: 767px) {
    padding: 24px 24px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 42px;

  font-size: 38px;
  line-height: 48px;
  text-align: center;

  opacity: 0.6;
`;

export const Input = styled(InputCustom)`
  margin-bottom: 24px;
`;

export const Button = styled(ButtonCustom)`
  margin-top: 24px;

  width: 150px;
  /* max-width: 300px; */
`;
