import React from "react";

import * as S from "./containers-root.styled";

export default function ContainersRootView({ children, ...props }) {
  return (
    <>
      {children}

      <S.Preloader>
        <S.Logo image="https://uploads-ssl.webflow.com/63a2d077108467714aa70e7f/63a2d27152e27a902933db16_Frame%202.svg" />
      </S.Preloader>
    </>
  );
}
