import React from "react";

import * as S from "./home.styled";

export default function HomeView({ loading, formik, validItems }) {
  return (
    <S.Container>
      <S.Content>
        <S.Title>
          DELETION OF
          <br />
          PERSONAL DATA
        </S.Title>

        <S.Input
          value={formik.values.email}
          placeholder="Email"
          status={!validItems.email ? "error" : ""}
          onChange={(event) => formik.setFieldValue("email", event.target.value)}
        />

        <S.Input
          value={formik.values.password}
          placeholder="Password"
          status={!validItems.password ? "error" : ""}
          onChange={(event) => formik.setFieldValue("password", event.target.value)}
        />

        <S.Button
          size="large"
          type="primary"
          loading={loading}
          onClick={formik.handleSubmit}
        >
          Send
        </S.Button>
      </S.Content>
    </S.Container>
  );
}
