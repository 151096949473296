import React, { useMemo, useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

import HomeView from "./home.view";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

export default function HomeContainer({ ...props }) {
  let [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSublit(values);
    },
  });

  const validItems = useMemo(() => {
    return {
      email: !formik.touched.email || !formik.errors.email,
      password: !formik.touched.password || !formik.errors.password,
    };
  }, [formik.touched, formik.errors]);

  const handleSublit = async (values) => {
    setLoading(true);

    const notificationId = toast.loading("Please wait...");

    try {
      const res = await axios.delete(`https://api.dr-grace.ai/users?email=${values.email}&password=${values.password}`);

      toast.update(notificationId, {
        render: "You have successfully deleted your account",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });

      setLoading(false);

      formik.resetForm()
    } catch (error) {
      let message = "Something went wrong, please try again!"

      if (error.response.data.message === "password_not_correct") {
        message = "You have entered a wrong password"
      } else if (error.response.data.message === "user_not_found") {
        message = "This user does not exist"
      }

      toast.update(notificationId, {
        render: message,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });

      setLoading(false);
    }
  };

  return (
    <HomeView
      {...props}
      formik={formik}
      loading={loading}
      validItems={validItems}
    />
  );
}
