import styled, { keyframes } from "styled-components";

const preloaderAnimation = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
  }
  50% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
`;

export const Preloader = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;

  z-index: 1;
  visibility: hidden;

  animation-name: ${preloaderAnimation};
  animation-duration: 4s;
`;

const logoAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
`;

export const Logo = styled.div`
  width: 250px;
  height: 124px;
  flex-shrink: 0;

  border-radius: 50%;
  background-color: #eee;

  background: url(${(props) => props.image}) no-repeat center;
  background-size: cover;

  animation-name: ${logoAnimation};
  animation-duration: 4s;
  animation-iteration-count: infinite;
`;
