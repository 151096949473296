import "./App.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomeScreen from "./domains/home";

import RootContainer from "./containers/root";

function App() {
  return (
    <RootContainer>
      <HomeScreen />

      <ToastContainer />
    </RootContainer>
  );
}

export default App;
